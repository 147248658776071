.simple-modal {
  display: flex;
  justify-content: center;
  height: 348px;
  .modal-dialog {
    max-width: 600px;
    .modal-content {
      padding: 30px;
      border-radius: 20px;
      border: none;
    }
  }
  h2 {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }
  h3 {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }
  p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.62);
    margin: 0 auto;
    margin-top: 30px;
  }
  &__btn-div {
    display: flex;
    justify-content: space-evenly;
    margin-top: 64px;
  }
  @media screen and (max-width: 550px) {
    &__btn-div {
      flex-direction: column;
      button {
        margin-bottom: 20px;
      }
    }
  }
}