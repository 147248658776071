@import "../../main.scss";
.App {
  position: relative;
}

.snackbar-container {
  display: table;
  overflow: hidden;
}

.status-icons {
  position: absolute;
  width: 100%;
  height: 100%;
}

.tooltip {
  font-size: large;
}

.muiButton:focus,
.muiButton:hover {
  box-shadow: none;
  outline: 1px solid black;
}

.muiButton:hover {
  border: none;
}

.overview-namec {
  margin: 0;
  font-family: "Intersate-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  color: $primary;
  font-weight: 800;
  font-size: 12px;
  text-align: left;
}

.snackbar-message {
  display: table-cell;
  vertical-align: middle;
}

.icon-snackbar {
  float: left;
}

.header-calendar {
  position: relative;
  top: 0;
}

.font-provider {
  font-family: "Intersate-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 18px !important;
  font-weight: 700 !important;
  display: table-cell;
  vertical-align: bottom;
  text-align: bottom;
}

.font-provider-calendar {
  font-family: "Open Sans", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  color: $primary;
  font-weight: 700;
  font-size: 12px;
  vertical-align: top;
}

.header-calendar-icon {
  margin: 0;
  margin-bottom: 3px;
  margin-right: 7px;
  padding: 0;
}

.timeline-border {
  background: rgba(63, 62, 62, 0.8);
  position: absolute;
  opacity: 1;
  padding: 0;
  z-index: 100;
  right: -13%;
  width: 100%;
  overflow: hidden;
  bottom: 4px;
  border-style: none!important;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  border: none !important;
}

.toolbar {
  width: 100%;
  text-align: center;
}

.container-button {
  position: absolute;
  width: 100%;
  height: 10px;
  text-align: center;
}

@media screen and (min-width: 1px) and (max-width: 800px) {
  .mobile-close-button {
    display: block !important;
  }
}

@media screen and (min-width: 801px) {
  .mobile-close-button {
    display: none !important;
  }
}

.accor-btn {
  position: relative;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  top: -100%;
  background-color: white;
  opacity: 1;
  z-index: 150;
  width: 50px;
  height: 20px;
  border-radius: 8px;
}

.accor-btn:active,
.accor-btn:focus {
  outline: none;
}

.tl-btn-end,
.tl-btn-start {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border: 1px solid black !important;
  border-radius: 10px;
  width: 30px;
}

.tl-btn-end {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: none !important;
}

.tl-btn-start {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: none !important;
}

.tl-btn-end:hover,
.tl-btn-start:hover {
  opacity: 1;
  cursor: pointer;
  background-color: rgba(35, 32, 32, 0.48);
}

.tl-btn-end:focus,
.tl-btn-start:focus {
  outline: none;
  box-shadow: none;
}

.toolbar-btn {
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 30px;
  margin: 0;
}

.toolbar-btn:focus {
  outline: 0;
}

.caretwrapper {
  background-color: white;
  border-radius: 18px;
  width: 50px;
  height: 20px;
  display: inline-block;
}

.timeline-button {
  position: absolute;
  top: 15px;
  left: 70px;
}

.timeline-button_ {
  position: absolute;
  top: 15px;
  right: 70px;
}

.timeline-container {
  background-color: $tertiary;
  height: 100%;
  width: 100%;
}

.camera-popup-exp {
  color: #000;
  background: #e5e5e5d1;
  .modal-dialog {
    max-width: 130vh;
  }
  .modal-content {
    background: none;
    border: none;
  }
  &__close-btn {
    background: none;
    outline: none !important;
    border: none;
    margin-bottom: 9px;
  }
  .mob-btn {
    display: none;
  }
  .expand,
  .expand-archive,
  .expand-btn--max {
    position: absolute;
    border: black 1px solid;
    background-color: black;
    opacity: 1;
    top: 35px;
    right: 7px;
    outline: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    background-color: transparent;
    border-left: none;
    background-color: transparent;
  }
  .mod {
    visibility: hidden;
  }
  .mod_ {
    visibility: visible;
  }
  @media screen and (max-width: 800px) {
    .mod,
    .mod_,
    .expand {
      visibility: hidden !important;
    }
  }
  .expand-btn--max {
    right: 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-right: none;
    border-left: black 1px solid !important;
  }
  .expand-btn--max:hover,
  .expand:hover {
    opacity: 1;
    cursor: pointer;
    background-color: rgba(35, 32, 32, 0.48);
  }
  .expand-archive {
    bottom: 45px;
  }
  .special {
    width: 60px !important;
    border-radius: 18px !important;
  }
  .image-container {
    position: relative;
  }
  .btn-left--mod {
    left: 0;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .btn-left--mod:hover,
  .btn-right--mod:hover {
    opacity: 0.5;
  }
  .btn-left--mod:focus,
  .btn-right--mod:focus {
    outline: none;
    border: none;
  }
  .btn-right--mod:active,
  .btn-left--mod:active {
    box-shadow: inset 0px 0px 10px 10px gray;
  }
  .btn-right--mod {
    right: 0;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 10px;
  }
  .btn-properties {
    width: auto;
    position: absolute;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    outline: none;
    outline: none;
    border: none;
  }
  .btn-content {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    opacity: 1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
  }
  @media screen and (max-width: 900px) {
    .scrollbtn {
      display: none;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    p {
      color: $primary;
      font-weight: bold;
      font-size: 12px;
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
    }
    h5 {
      font-size: 18px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  &__date-time {
    display: flex;
    justify-content: center;
    margin-left: 32px;
    margin-top: 3px;
    p {
      margin-left: 10px;
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif !important;
      font-style: normal;
    }
  }
  &__camera-img {
    width: 100%;
    border-radius: 18px;
  }
  .default_img {
    background: #e5e5e5;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  &__btn-sec {
    display: flex;
    flex-direction: row;
    margin: 12px 7px;
    justify-content: space-between;
    h6 {
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 140.62%;
      color: rgba(0, 0, 0, 0.62);
      width: 130px;
    }
    input {
      background: none;
      border: none;
      width: 40%;
    }
    &--first-span {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 50%;
    }
    &--sec-span {
      display: flex;
      flex-direction: row;
      width: 50%;
    }
    &--sub-sec {
      display: flex;
      width: 85%;
      p {
        font-size: 12px;
        margin-right: 20px;
        margin-bottom: 0;
        padding-top: 6px;
        font-family: "Open Sans", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
        font-style: normal;
      }
    }
  }
  &__opt-btns {
    display: flex;
    justify-content: space-evenly;
    margin-top: 26px;
    &:focus {
      border: none;
      outline: 0;
    }
    .btn-primary {
      border-radius: 50px;
      height: 50px;
      width: 180px;
      svg {
        margin-right: 6px;
      }
    }
  }
  @media screen and (max-width: 550px) {
    padding-right: 0;
    .modal-dialog {
      margin: 0;
    }
    &__head {
      h5 {
        margin-bottom: 39px;
      }
      .d-flex {
        vertical-align: bottom;
        margin: 0 auto;
      }
    }
    &__date-time {
      margin-left: 10px;
      margin-bottom: 20px;
    }
    &__close-btn {
      display: none;
    }
    .mob-btn {
      display: flex;
      justify-content: flex-end;
      background: none;
      outline: none !important;
      border: none;
      margin-right: 15px;
    }
    &__camera-img {
      border-radius: 0;
    }
    &__btn-sec {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h6 {
        margin-bottom: 15px !important;
      }
      &--sub-sec {
        justify-content: space-between;
        width: 85%;
        &-p {
          margin-bottom: 5px !important;
        }
      }
      &--first-span {
        flex-direction: column;
      }
      &--sec-span {
        display: flex;
        flex-direction: column;
        width: 50%;
        input {
          width: 40%;
        }
        p {
          margin-bottom: 5px;
        }
      }
    }
    &__opt-btns {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .btn-primary {
        margin-bottom: 20px;
        width: 90%;
      }
      &--re-generate-btn {
        width: 90%;
      }
      &--copy-url {
        width: 90%;
      }
      &--download {
        width: 90% !important;
        background: none !important;
        border: 1px solid #000000 !important;
        border-color: #000000;
      }
    }
  }
}

.camera-popup {
  color: #000;
  background: #e5e5e5d1;
  .modal-dialog {
    max-width: 850px;
  }
  @media screen and (max-width: 1150px) {
    .modal-dialog {
      max-width: 650px !important;
    }
  }
  @media screen and (min-width: 3840px) {
    .modal-dialog {
      max-width: 60% !important;
    }
  }
  .modal-content {
    background: none;
    border: none;
  }
  .expand,
  .expand-archive,
  .expand-btn--max {
    position: absolute;
    border: black 1px solid;
    background-color: black;
    opacity: 1;
    top: 35px;
    right: 7px;
    outline: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    background-color: transparent;
    border-left: none;
  }
  .expand-btn--max {
    right: 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 0 !important;
    border-right: none;
    border-left: black 1px solid !important;
  }
  .expand-btn--max:hover,
  .expand:hover {
    opacity: 1;
    cursor: pointer;
    background-color: rgba(35, 32, 32, 0.48);
  }
  .expand-archive {
    bottom: 40px;
  }
  .mod {
    visibility: hidden;
  }
  .mod_ {
    visibility: visible;
  }
  @media screen and (max-width: 800px) {
    .mod,
    .mod_,
    .expand {
      visibility: hidden !important;
    }
  }
  &__close-btn {
    background: none;
    outline: none !important;
    border: none;
    margin-bottom: 9px;
  }
  .mob-btn {
    display: none;
  }
  .image-container {
    position: relative;
  }
  .btn-left--mod {
    left: 0;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  .btn-left--mod:hover,
  .btn-right--mod:hover {
    opacity: 0.5;
  }
  .btn-left--mod:focus,
  .btn-right--mod:focus {
    outline: none;
    border: none;
  }
  .btn-right--mod:active,
  .btn-left--mod:active {
    box-shadow: inset 0px 0px 10px 10px gray;
  }
  .btn-right--mod {
    right: 0;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0px;
  }
  .btn-properties {
    width: auto;
    position: absolute;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    outline: none;
    outline: none;
    border: none;
  }
  .btn-content {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    opacity: 1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
  }
  @media screen and (max-width: 900px) {
    .scrollbtn {
      display: none;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    p {
      color: $primary;
      font-weight: bold;
      font-size: 12px;
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
    }
    h5 {
      font-size: 18px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  &__date-time {
    display: flex;
    justify-content: center;
    margin-left: 32px;
    margin-top: 3px;
    p {
      margin-left: 10px;
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif!important;
      font-style: normal;
    }
  }
  &__camera-img {
    width: 100%;
    border-radius: 18px;
  }
  .default_img {
    background: #e5e5e5;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  &__btn-sec {
    display: flex;
    flex-direction: row;
    margin: 12px 7px;
    justify-content: space-between;
    h6 {
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 140.62%;
      color: rgba(0, 0, 0, 0.62);
      width: 130px;
    }
    input {
      background: none;
      border: none;
      width: 40%;
    }
    &--first-span {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 50%;
    }
    &--sec-span {
      display: flex;
      flex-direction: row;
      width: 50%;
    }
    &--sub-sec {
      display: flex;
      width: 85%;
      p {
        font-size: 12px;
        margin-right: 20px;
        margin-bottom: 0;
        padding-top: 6px;
        font-family: "Open Sans", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
        font-style: normal;
      }
    }
  }
  &__opt-btns {
    display: flex;
    justify-content: space-evenly;
    margin-top: 26px;
    &:focus {
      border: none;
      outline: 0;
    }
    .btn-primary {
      border-radius: 50px;
      height: 50px;
      width: 180px;
      svg {
        margin-right: 6px;
      }
    }
  }
  @media screen and (max-width: 550px) {
    padding-right: 0;
    .modal-dialog {
      margin: 0;
    }
    &__head {
      h5 {
        margin-bottom: 39px;
      }
      .d-flex {
        vertical-align: bottom;
        margin: 0 auto;
      }
    }
    &__date-time {
      margin-left: 10px;
      margin-bottom: 20px;
    }
    &__close-btn {
      display: none;
    }
    .mob-btn {
      display: flex;
      justify-content: flex-end;
      background: none;
      outline: none !important;
      border: none;
      margin-right: 15px;
    }
    &__camera-img {
      border-radius: 0;
    }
    &__btn-sec {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h6 {
        margin-bottom: 15px !important;
      }
      &--sub-sec {
        justify-content: space-between;
        width: 85%;
        &-p {
          margin-bottom: 5px !important;
        }
      }
      &--first-span {
        flex-direction: column;
      }
      &--sec-span {
        display: flex;
        flex-direction: column;
        width: 50%;
        input {
          width: 40%;
        }
        p {
          margin-bottom: 5px;
        }
      }
    }
    &__opt-btns {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .btn-primary {
        margin-bottom: 20px;
        width: 90%;
      }
      &--re-generate-btn {
        width: 90%;
      }
      &--copy-url {
        width: 90%;
      }
      &--download {
        width: 90% !important;
        background: none !important;
        border: 1px solid #000000 !important;
        border-color: #000000;
      }
    }
  }
}