@import "../main.scss";
.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
  ul {
    display: flex;
    padding: 3px 5px 0px 5px;
    margin: 0px;
  }
  li {
    align-items: center;
    display: flex;
    list-style: none;
    padding: 10px;
    &:focus {
      outline: 0;
      border: none;
    }
  }
  a {
    border: none;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    &:focus {
      outline: 0;
      border: none;
    }
  }
  .pagination-active {
    color: $primary;
  }
  .pagination__number {
    color: gray;
  }
  .pagination__btn {
    align-items: center;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
    border-radius: 80px;
    height: 35px;
    width: 35px;
    padding: 10px;
  }
}