@import "../main.scss";
.no-styles {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.datepicker-container {
  position: relative;
  width: 10px;
  top: 0%;
  left: -50%;
  opacity: 0;
}

.calendar-icon {
  position: relative;
  top: 30%;
}

.date-picker {
  width: 1px;
  height: 1px;
  opacity: 0;
}

.first-part {
  position: relative;
  float: left;
}

.second-part {
  position: relative;
  float: right;
  margin-top: 2;
}

.main-box {
  background-color: rgba($color: $tertiary, $alpha: 0.3);
}

.Slider {
  background-color: rgba($color: $tertiary, $alpha: 0.3) !important;
}

.slider-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 1px;
    height: 1px;
    background-color: #9fc5ea;
    width: 100%;
  }
}

.slider-scale-dot {
  width: 3px;
  height: 3px;
  font-size: 25px;
  background-color: $primary;
  line-height: 12pt;
  cursor: pointer;
}

.first-part {
  background: #f0f0f0;
  padding: 4px 7px;
  border-radius: 10px;
  box-shadow: rgba(204, 204, 204, 0.7) 0px 0px 15px;
  span {
    display: flex;
    align-items: center;
  }
  &:hover {
    background-color: #c6c6c6;
  }
}