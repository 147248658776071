@import "../../main.scss";
.Archive {
  position: relative;
  padding: 15px 30px;
  &__loadmore {
    align-items: center;
    cursor: pointer;
    color: $primary;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    text-align: center;
  }
  .image-container {
    position: relative;
  }
  &__filter-btn {
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: none;
    outline: none !important;
  }
  .css-1gtu0rj-indicatorContainer {
    display: none;
  }
  &__navbar {
    padding: 0 10px;
    margin-bottom: 15px;
    &--left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    &--by-date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      width: 100%;
    }
    &--right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      width: 63%;
      input {
        height: 36px;
        font-size: 14px;
        padding: 0px 10px;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        //border-radius: 19px !important;
        margin-left: 5px;
        margin-right: 5px;
        outline: none;
        color: rgba(0, 0, 0, 0.62);
        /*        &:focus {
          border: 1px solid #c4c4c4 !important;
        } */
      }
    }
    &--date-time-main {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0px !important;
      margin-left: 30px;
      label {
        padding-right: 5px;
        font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }
    }
    &--date-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 10px;
    }
    &--weekdays-main-div {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-left: 50%;
      .weekdays {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 35px;
        p {
          font-size: 14px;
          margin: 0;
        }
      }
    }
    &--day {
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.62);
      width: 30%;
      text-align: center;
      height: 30px;
    }
    &--day-selected {
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.62);
      width: 30%;
      text-align: center;
      border-bottom: 2px solid $primary;
      height: 30px;
    }
    &-time-picker {
      display: flex;
      justify-content: center;
      align-items: center;
      .rc-time-picker-clear {
        top: 6px;
      }
    }
  }
  .card {
    padding-top: 0px;
    margin-bottom: 30px;
  }
  &__menu {
    display: flex;
    align-items: center;
  }
  &__hamburger-menu {
    background: none;
    border: none;
    outline: none !important;
  }
  &__apps {
    background: $primary;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    border: none;
    outline: none !important;
    display: flex;
    align-items: center;
  }
  &__dropdown {
    align-items: center;
    display: flex;
    position: relative;
    .drop-down {
      width: 135px;
      margin-left: 8px;
      align-items: center;
      display: flex;
      .btn-primary {
        margin: 0;
        font-weight: bold;
      }
      .dropdown-toggle,
      .drop-down,
      .show>.btn-primary {
        border: none !important;
        font-size: 14px !important;
        margin-top: -3px !important;
        height: 26px !important;
        outline: none !important;
        padding-left: 0 !important;
        margin-left: 5px;
        font-weight: bold;
        &:focus {
          outline: none !important;
          border: none !important;
          box-shadow: none;
        }
      }
      label {
        font-weight: 600;
        font-size: 14px;
        color: #0000009e;
        margin: 0px;
        input {
          font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
        }
      }
      .btn-primary:not(:disabled):not(.disabled):active:focus,
      .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
      }
    }
  }
  &__camera-img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    img {
      width: 100%;
      height: 300px;
      border-radius: 20px;
    }
    &-default-thumbnail {
      background: #E5E5E5;
      height: 273px;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100px !important;
        width: auto !important;
      }
    }
    &-default-tile {
      display: flex;
      margin-right: 24px;
      width: 380px;
      justify-content: center;
      align-items: center;
      img {
        padding: 12px;
        height: 81px;
        width: 85px;
      }
    }
  }
  &__all-camera-btn {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.62);
  }
  &__cam-main {
    display: "flex";
    flex-direction: "column";
  }
  &__cam-Heading {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 140.62%;
    color: rgba(0, 0, 0, 0.62);
    margin-left: 8px;
    margin-bottom: 25px;
    &-arrows {
      display: none;
    }
  }
  &__cam-info {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    font-weight: 800;
    font-size: 18px;
    margin-bottom: 20px;
    color: #0000009e;
    &--date-time {
      color: $primary;
      font-size: 12px;
      display: flex;
      padding-top: 5px;
      p {
        padding-left: 5px;
        margin-bottom: 0;
      }
      &-delete {
        cursor: pointer;
      }
    }
  }
  &__camName {
    margin-top: 30px;
  }
  &__tile {
    display: flex;
    height: 120px;
    background: #f9f9f9;
    border-radius: 15px;
    margin-bottom: 10px;
    &--img {
      height: 100%;
      max-width: 150px;
      border-radius: 15px;
      margin-right: 5px;
      object-fit: cover;
    }
    &--date-time {
      color: $primary;
      font-size: 11px;
      display: flex;
      padding-top: 5px;
      p {
        padding-left: 5px;
        margin-bottom: 0;
      }
      font-family: 'Open Sans',
      'Roboto',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      color: $primary;
    }
    &-delete {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 16px;
      cursor: pointer;
      font-weight: 800;
      font-size: 18px;
      color: #0000009e;
    }
    &-delete-col {
      display: flex;
      justify-content: flex-end;
      padding-right: 16px;
      padding-top: 10px;
      color: #0000009e;
      cursor: pointer;
      font-size: 18px;
      width: 30px !important;
    }
  }
  &__date-time {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 140.62%;
    color: $primary;
    margin: 0px;
    white-space: nowrap;
    p {
      margin: 0px;
      padding-left: 10px;
    }
    padding-left: 10px;
    &-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__reset-btn {
    border: none;
    background: transparent;
  }
  .row {
    span {
      display: flex;
      flex-wrap: nowrap;
      width: 94%;
    }
  }
  .pagination {
    margin-bottom: 0;
  }
  &__main {
    margin: 48px 0px 24px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 32px;
    }
  }
  &__mobile-filter-sec {
    display: none;
  }
  .By-period-line {
    display: none;
  }
  &-main-for-invidual-camera {
    width: 100%;
    cursor: pointer;
    .Archive__no-Camera {
      width: 100%;
      display: flex;
      justify-content: center;
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-weight: 600;
      height: 130px;
      font-size: 20px;
      align-items: center;
      padding: 0px 20px;
    }
  }
  .icon {
    margin-top: 0;
    right: -4px;
    top: 3px;
    cursor: pointer;
  }
}

@media screen and (max-width: 769px) {
  .Archive {
    &__navbar--right {
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
      input {
        height: 36px;
        padding: 10px;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        //border-radius: 19px;
        margin-left: 5px;
        margin-right: 5px;
        outline: none;
        width: 160px;
        font-size: 14px;
        &:focus {
          border: 1px solid #c4c4c4 !important;
        }
      }
    }
    &__navbar--date-time-main {
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .drop-down {
      width: 110px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .Archive {
    .Archive__menu {
      display: none;
    }
    &__all-camera-btn {
      display: none;
    }
    &__dropdown {
      display: none;
    }
    &__reset-btn {
      display: none;
    }
    &__navbar--weekdays-main-div {
      width: 100%;
      margin-left: 0px;
    }
    &__navbar--right {
      width: 100%;
      justify-content: center;
    }
    &__navbar--date-time-main {
      justify-content: space-evenly;
    }
    &__mobile-filter-sec {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 63px;
      &-span {
        display: flex;
        align-items: center;
      }
      &-date-picker {
        height: 36px;
        padding: 0px 10px;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 19px !important;
        margin-left: 5px;
        margin-right: 5px;
        outline: none;
        color: rgba(0, 0, 0, 0.62);
        font-size: 14px;
      }
    }
    &__dropdown-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__navbar--by-date {
      display: none;
    }
    .By-period-line {
      display: block;
    }
  }
}

@media screen and (max-width: 650px) {
  .Archive {
    padding: 3px;
    padding-right: 3px;
    &__navbar--right input {
      height: 36px;
      padding: 10px;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      //border-radius: 19px;
      margin-left: 5px;
      margin-right: 5px;
      outline: none;
      width: 120px;
      &:focus {
        border: 1px solid #c4c4c4 !important;
      }
    }
    &__tile {
      display: flex;
      height: 99px;
      margin-bottom: 10px;
      margin-top: 10px;
      background: #f9f9f9;
      border-radius: 30px;
    }
    &__cam-Heading {
      display: flex;
      justify-content: center;
      &-arrows {
        display: flex;
      }
    }
    &__mobile-filter-sec {
      padding-right: 10px;
      padding-left: 10px;
    }
    .Archive__no-Camera {
      font-size: 16px;
    }
  }
  .Archive__navbar--day-selected {
    height: 50px;
  }
}

@media screen and (max-width: 330px) {
  .Archive {
    &__tile--img {
      width: 130px;
      height: 100%;
    }
    &__date-time {
      font-size: 10px;
      p {
        padding-left: 5px;
      }
    }
  }
}