@import "../main.scss";
.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.62);
  margin-bottom: 0;
}

.inputField {
  height: 54px !important;
  background: #fbfbfb;
  box-shadow: inset 0px 0px 20px rgba(222, 222, 222, 0.53);
  border-radius: 80px !important;
  border: none !important;
  margin-top: 14px;
  padding: 0px 17px !important;
  font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 18px !important;
  &::-webkit-input-placeholder {
    color: #000000 !important;
  }
}

.textMuted {
  text-align: right;
  margin-bottom: 45px;
  font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: $primary !important;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .inputField {
    width: 100% !important;
    height: 46px !important;
  }
}