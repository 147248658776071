.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__baucamera-logo {
    width: 118px;
    height: 19px;
  }
  &__title {
    font-size: 50px;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 900;
    margin-top: 44px;
    margin-bottom: 34px;
    text-align: center;
  }
  &__login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  &__error {
    margin-top: 20px;
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 10px
  }
  @media screen and (max-width: 550px) {
    &__title {
      font-size: 36px;
    }
  }
}