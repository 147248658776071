@import "../../main.scss";
.projects {
  .projects__cover {
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    height: 100px;
    justify-content: space-between;
    padding: 0px 28px;
    .projects__header {
      font-size: 50px;
    }
    .project__cover-reload-btn {
      background-color: $tertiary;
      box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
      border-radius: 80px;
      height: 50px;
      font-family: "Intersate-Light", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      border: none;
      width: 185px;
      &:focus {
        border: none;
        outline: 0;
      }
    }
  }
  .projects__reload-header {
    display: none;
  }
  .projects__filter {
    align-items: center;
    box-shadow: 4px 23px 30px -33px #c4c4c4;
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;
    padding: 0px 28px;
    .projects__filter-right {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      align-content: center;
      .css-2b097c-container {
        width: 200px;
        margin-left: 20px;
        .css-yk16xz-control {
          border-radius: 50px;
        }
        .css-1okebmr-indicatorSeparator,
        .css-tlfecz-indicatorContainer,
        .css-6q0nyr-Svg {
          display: none;
        }
        .css-1pahdxg-control {
          border-radius: 40px;
          box-shadow: none;
          &:hover {
            border-color: #1181F2;
          }
        }
      }
      .user__filter-reload {
        margin-left: 12px;
      }
    }
  }
  .thumbnails-row {
    padding: 7rem 28px 0px 28px;
    box-shadow: inset 0px 2px 30px rgba(0, 0, 0, 0.05);
    .default_img {
      background: #E5E5E5;
      height: 100%;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
    }
    .projects__thumnail {
      .card {
        align-items: flex-start;
        border: none;
        justify-content: flex-start;
        overflow: hidden;
        padding-top: 0px;
      }
      h6 {
        border-bottom: 1px solid #bdbdbd;
        color: #000000;
        font-family: $h1-font;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        margin-bottom: 16px;
        padding: 12px 0px;
        width: 100%;
      }
      p {
        font-family: $p-font;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140.62%;
        color: #000000;
      }
      a:hover {
        text-decoration: none;
      }
      .project__thumbnail-img {
        align-items: center;
        justify-content: center;
        height: 250px;
        display: flex;
        width: 100%;
        .projects__cameras {
          display: flex;
          position: relative;
          height: 100%;
          width: 100%;
          .projects__camera-img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
          .project__default-image {
            object-fit: cover;
            width: 100%;
            background: #E5E5E5;
            height: 100%;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .projects__camera-name {
            background-color: rgba(255, 255, 255, 0.753);
            bottom: 0px;
            color: #000000;
            font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
            font-size: 10px;
            padding: 5px 0px;
            position: absolute;
            text-align: center;
            width: 100%;
          }
        }
      }
      .project__thumbnails-info {
        padding: 12px;
        width: 100%;
      }
    }
    .thumbnails__col {
      margin-bottom: 28px;
    }
    .projects__thumbnail-empty {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 130px;
      font-size: 26px;
      align-items: center;
    }
  }
  .pagination {
    margin: 72px 17px;
  }
  .projects__filter-left {
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    width: 90px;
    .project__filter-btn {
      align-items: center;
      display: flex;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      border: none;
      margin-right: 15px;
      outline: none !important;
    }
  }
  .project__google-map {
    padding: 32px 28px 60px;
  }
  .icon {
    margin-top: 0;
  }
  &__subContainer {
    padding-top: 20px;
  }
  &__header-btn {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    padding-left: 40px;
    .css-2b097c-container {
      width: 200px;
      .css-yk16xz-control {
        border-radius: 50px;
      }
      .css-1pahdxg-control {
        border-radius: 50px;
      }
      .css-1okebmr-indicatorSeparator,
      .css-6q0nyr-Svg {
        display: none;
      }
    }
  }
  &__searchInput {
    width: 200px;
    border-radius: 50px;
    height: 3.8em;
    color: #000;
    border-color: #d4d4d4 !important;
    border-image: none;
    box-shadow: none;
    border-style: none;
    border: 1px solid;
    padding: 0px 10px;
    &:focus {
      width: 200px;
      border-radius: 50px;
      height: 3.8em;
      color: #000;
      border-image: none;
      box-shadow: none;
      border-style: none;
      border: 1px solid;
      border-color: #d4d4d4;
      padding: 0px 10px;
    }
  }
  &__dropdown-btn {
    .dropdown-toggle {
      margin-bottom: 0px !important;
    }
    .drop-down {
      display: flex;
      label {
        margin-bottom: 0;
      }
      .icon {
        margin-top: 0;
      }
      .btn-primary {
        margin-right: 0;
        font-weight: bold;
      }
      .dropdown-toggle,
      .drop-down,
      .show>.btn-primary {
        border: none !important;
        font-size: 14px !important;
        margin-top: 0px !important;
        height: 28px !important;
        outline: none !important;
        padding-left: 0 !important;
        margin-left: 7px;
        font-weight: bold;
        &:focus {
          outline: none !important;
          border: none !important;
          box-shadow: none;
        }
      }
      label {
        display: flex;
        font-size: 14px;
        color: #0000009e;
        align-items: center !important;
      }
      .btn-primary:not(:disabled):not(.disabled):active:focus,
      .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
      }
      .icon {
        cursor: pointer;
        margin-top: -2px;
      }
    }
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    .pagination-arrow {
      align-items: center;
      border: none;
      display: flex;
      font-size: 14px;
      margin: 15px;
      &:hover {
        color: #1181F2;
      }
      &:focus {
        outline: 0;
        border: none;
      }
    }
    .pagination__page {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
  .pagination__btn {
    align-items: center;
    display: flex;
    justify-content: center;
    background: $tertiary;
    box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
    border-radius: 80px;
    height: 35px;
    width: 35px;
    padding: 10px;
  }
  @media screen and (max-width: 768px) {
    &__camera-img {
      height: calc(130px + 13vw);
    }
  }
  @media screen and (max-width: 550px) {
    padding-top: 12px;
    &__camera-img {
      height: calc(130px + 13vw);
    }
    &__newCamera-btn {
      .btn-primary {
        padding: 17px;
        width: 90%;
      }
    }
    &__header-btns {
      padding-left: 0;
    }
    &__dropdown-btn {
      .drop-down {
        margin-left: 2rem;
        left: 7px;
        label {
          font-size: 12px !important;
          min-width: 5em !important;
        }
        .icon {
          right: 11px;
        }
      }
    }
    &__searchInput {
      width: 35vw;
      border-radius: 50px;
      height: 4.8em;
      color: #000;
      border-color: #d4d4d4 !important;
      border-image: none;
      box-shadow: none;
      border-style: none;
      border: 1px solid;
      padding: 0px 10px;
      &:focus {
        width: 35vw;
        border-radius: 50px;
        height: 4.8em;
        color: #000;
        border-image: none;
        box-shadow: none;
        border-style: none;
        border: 1px solid;
        border-color: #d4d4d4;
        padding: 0px 10px;
      }
    }
    &__header-btn {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      padding-left: 10px;
      .css-2b097c-container {
        width: 35vw;
        .css-yk16xz-control {
          border-radius: 50px;
        }
        .css-1pahdxg-control {
          border-radius: 50px;
        }
        .css-1okebmr-indicatorSeparator,
        .css-6q0nyr-Svg {
          display: none;
        }
      }
    }
    .row {
      padding-top: 20px;
      padding: 45px 6px 10px;
    }
    &__online-btn {
      display: block;
      position: absolute;
      top: -64px;
      right: 10px;
      width: 30% !important;
    }
    &__reset-btn {
      top: -51px;
    }
  }
}

@media screen and (min-width: 551px) {
  .projects {
    .projects__filter-left--mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 300px) {
  .overview {
    &__dropdown {
      .drop-down {
        margin-left: 0px !important;
        left: 0px !important;
        label {
          min-width: 0px !important;
        }
      }
      .drop-down-span {
        margin-right: 0px !important;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .projects {
    .projects__cover {
      display: flex;
      border-bottom: 1px solid #d7d7d7;
      height: 73px;
      .projects__header {
        display: none;
        width: 100%;
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        padding: 20px 17px;
      }
      .project__cover-reload-btn {
        display: none;
      }
    }
    .projects__reload-header {
      display: flex;
      padding: 14px;
      .project__cover-reload-btn {
        background-color: $tertiary;
        box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
        border-radius: 80px;
        height: 50px;
        font-family: "Intersate-Light", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        border: none;
        width: 100%;
        &:focus {
          border: none;
          outline: 0;
        }
      }
    }
    .projects__filter {
      padding: 0px 14px;
      .projects__filter-right {
        .projects__filter-dropdown {
          margin-right: 0px;
          .drop-down {
            .dropdown-toggle {
              height: 18px;
              font-size: 12px !important;
            }
            .dropdown-item {
              font-size: 9px;
            }
            .show>.btn-primary {
              font-size: 12px !important;
            }
          }
        }
        label {
          font-size: 11px;
        }
      }
    }
    .projects__filter-left--desktop {
      display: none;
    }
    .thumbnails__col {
      padding: 0px;
    }
    .projects__cover {
      padding: 14px;
    }
    .projects__filter {
      justify-content: center;
      .projects__filter-right {
        .css-2b097c-container {
          width: 145px;
          margin-left: 0px;
        }
      }
    }
  }
  .drop-down .dropdown-toggle {
    height: 18px;
  }
}