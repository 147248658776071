@import "../../main.scss";
.add-user {
  display: flex;
  justify-content: center;
  .icon {
    margin-top: -4px !important;
  }
  .modal-dialog {
    max-width: 1075px;
    .modal-content {
      border-radius: 20px;
      border: none;
    }
  }
  &__cross {
    margin-top: 22px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__cross-image {
    width: 21px;
    margin-right: 40px;
    cursor: pointer;
  }
  h2 {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .row {
    margin: 0 3rem;
  }
  form {
    margin-top: 25px;
    padding: 0 2rem;
    margin-bottom: 51px;
    .drop-down {
      .dropdown-menu.show {
        width: 100%;
        margin-left: 45px !important;
      }
      .drop-down-span {
        position: relative;
      }
      .icon {
        position: absolute;
      }
    }
  }
  .container {
    margin-top: 2rem;
    label {
      margin-left: 3.5rem;
      margin-bottom: 1.5rem;
    }
  }
  &__project-container {
    margin-top: 20px;
  }
  &__projects-sec {
    height: 290px;
    //box-shadow: inset 0px 2px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    justify-content: center;
    padding: 10px 0;
    margin-right: 0;
    margin-top: 5px;
    &-span {
      display: flex;
      width: 100%;
      padding: 1.5rem 3rem 1rem 3rem;
    }
    &-search-input {
      padding-left: 10px;
      width: 360px;
      height: 36px;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 19px;
      display: flex;
      align-items: center;
      &-icon {
        width: 20px;
        height: 20px;
      }
      input {
        width: calc(100% - 32px);
        border: none !important;
        outline: none !important;
        padding-left: 10px;
        font-size: medium;
      }
    }
    .col-md-12 {
      margin: 0.5rem 0;
      font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      &:hover {
        color: #268bf1;
        cursor: pointer;
      }
    }
  }
  &__projects-box {
    display: flex;
    flex-direction: column;
    height: calc(470px - 100%);
    overflow: auto;
    margin: 0 1rem;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
    padding: 20px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 32px;
    }
  }
  &__project-item {
    margin-bottom: 8px;
    cursor: pointer;
  }
  &__project-item--active {
    color: #268bf1;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 8px;
  }
  &__credentials {
    text-align: center;
    margin-bottom: -2em;
    margin-top: 2em;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
    color: $primary !important;
    cursor: pointer;
  }
  &__btn-div {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  .drop-down {
    margin-top: 10px;
  }
  @media screen and (min-width: 768px) {
    .add-user {
      .container {
        max-width: 910px;
      }
    }
  }
  @media screen and (min-width: 576px) {
    .add-user {
      .container {
        max-width: 645px;
      }
    }
  }
  @media screen and (max-width: 550px) {
    &__projects-sec {
      width: 100%;
    }
    h2 {
      font-size: 36px;
    }
    .container {
      margin: 12px 0px;
      padding: 0;
      max-width: 397px;
      margin-left: 14px;
      label {
        margin: 12px;
      }
    }
    .row {
      margin-left: 1rem;
    }
    .add-user__projects-sec-span {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}