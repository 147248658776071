@import "../../main.scss";
.add-company {
  display: flex;
  justify-content: center;
  .modal-dialog {
    margin-top: 15%;
    max-width: 650px;
    .modal-content {
      border-radius: 20px;
      border: none;
    }
  }
  &__cross {
    margin-top: 22px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__cross-image {
    width: 21px;
    margin-right: 40px;
    cursor: pointer;
  }
  h2 {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .row {
    margin: 0 3rem;
  }
  form {
    margin-top: 25px;
    padding: 0 2rem;
    margin-bottom: 51px;
  }
  .container {
    margin-top: 2rem;
    label {
      margin-left: 3.5rem;
      margin-bottom: 1.5rem;
    }
  }
  &__projects-sec {
    height: 250px;
    box-shadow: inset 0px 2px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    justify-content: center;
    padding: 10px 0;
    margin-right: 0;
    .col-md-6 {
      margin: 1rem 0;
      &:hover {
        color: #268bf1;
        cursor: pointer;
      }
    }
  }
  &__projects-box {
    height: 100%;
    width: 96%;
    overflow: auto;
    margin: 0 1rem;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 32px;
    }
  }
  &__btn-div {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    &__projects-sec {
      width: 100%;
      height: 242px;
    }
    h2 {
      font-size: 36px;
    }
    .container {
      margin: 12px 0;
      padding: 0;
      label {
        margin: 12px;
      }
    }
    .row {
      margin: 0;
      .col-sm-12 {
        padding: 0;
      }
    }
  }
}