@import "../main.scss";
.timelapse {
  height: 550px;
  width: 100%;
  position: relative;
  .timelapse__close {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: -30px;
    &:focus {
      outline: none;
    }
  }
  .timelapse__image {
    border-radius: 20px;
    height: auto;
    width: 100%;
  }
  .timelapse__live-cam {
    position: absolute;
    left: 0;
    margin-left: 5px;
    top: -35px;
    .timelapse__live-name {
      color: black;
      font-family: $h1-font;
      font-size: 18px;
      margin-right: 14px;
    }
    .timelapse__live-text {
      color: $secondary;
      font-family: $h1-font;
      font-size: 14px;
    }
  }
  &__button-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 10px;
    align-items: center;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    color: #ffff;
    font-size: 12px;
    font-weight: 600;
    width: 90px;
    padding: 10px;
    margin-right: 4px;
  }
  .select-dropdown {
    svg {
      position: absolute;
      right: 0;
    }
  }
  .select-dropdown .css-yk16xz-control {
    min-height: 28px;
    border: 1px solid grey;
    background: none;
    margin-left: 5px;
  }
  .select-dropdown .css-1pahdxg-control {
    min-height: 28px;
    border: 1px solid grey;
    background: none;
    margin-left: 5px;
    &:hover {
      border: 1px solid grey;
    }
  }
   :focus {
    outline: 0;
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 550px) {
  .timelapse {
    margin-top: 30%;
    width: 100%;
  }
}