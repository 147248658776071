@import "../main.scss";
.card {
  background: $tertiary;
  box-shadow: 0px -3px 30px #e5e5e5;
  border-radius: 20px !important;
  padding-top: 51px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
