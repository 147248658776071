@import "../../main.scss";
.overview {
  padding-top: 0;
  .status-container {
    position: relative;
    right: 150%;
  }
  .status-container__noblur {
    position: relative;
    right: 0;
  }
  .icon-offset {
    position: absolute;
    display: inline;
    top: 4px;
    right: -25px;
  }
  .tooltip {
    font-size: large;
  }
  &__newCamera-btn {
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    justify-content: center;
    padding: 17px;
  }
  &__sub-container {
    padding-top: 20px;
  }
  &__header-btns {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    padding-left: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    .css-2b097c-container {
      width: 200px;
      .css-yk16xz-control {
        border-radius: 50px;
      }
      .css-1pahdxg-control {
        border-radius: 50px;
      }
      .css-1okebmr-indicatorSeparator,
      .css-6q0nyr-Svg {
        display: none;
      }
    }
  }
  &__online-btn {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    display: none;
  }
  .overview__camera-projectname {
    font-family: "Intersate-Bold", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    text-decoration: none;
  }
  &__dropdown {
    .dropdown-menu {
      z-index: 1 !important;
    }
    .drop-down {
      display: flex;
      label {
        margin-bottom: 0;
      }
      .icon {
        margin-top: 0;
      }
      .btn-primary {
        margin-right: 0;
        font-weight: bold;
      }
      .dropdown-toggle,
      .drop-down,
      .show>.btn-primary {
        border: none !important;
        font-size: 14px !important;
        margin-top: 0px !important;
        height: 28px !important;
        outline: none !important;
        padding-left: 0 !important;
        margin-left: 7px;
        font-weight: bold;
        &:focus {
          outline: none !important;
          border: none !important;
          box-shadow: none;
        }
      }
      label {
        font-size: 14px;
        color: #0000009e;
        display: flex;
        align-items: center;
      }
      .dropdown-menu {
        margin-left: -1em !important;
      }
      .btn-primary:not(:disabled):not(.disabled):active:focus,
      .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
      }
      .icon {
        cursor: pointer;
        margin-top: 0px;
      }
    }
  }
  &__searchInput {
    width: 200px;
    border-radius: 50px;
    height: 3.8em;
    color: #000;
    border-color: #d4d4d4 !important;
    border-image: none;
    box-shadow: none;
    border-style: none;
    border: 1px solid;
    padding: 0px 10px;
    &:focus {
      width: 200px;
      border-radius: 50px;
      height: 3.8em;
      color: #000;
      border-image: none;
      box-shadow: none;
      border-style: none;
      border: 1px solid;
      border-color: #d4d4d4;
      padding: 0px 10px;
    }
  }
  &__reset-btn {
    background: none;
    border: none;
    outline: none !important;
    top: 2px;
    margin-left: 5px;
  }
  &__camera-img {
    cursor: pointer;
    height: calc(50px + 13vw);
    .stream_img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      object-fit: cover;
    }
    .default_img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
  .card {
    padding-top: 0;
    margin-top: 11px;
    display: block;
    align-items: unset;
    //background: #f9f9f9;
    border: none;
  }
  &__cam-info {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    font-weight: 800;
    font-size: 16px;
    color: #0000009e;
    &--date-time {
      color: $primary;
      font-size: 10px;
      display: flex;
      padding-top: 5px;
      p {
        font-family: "Intersate-Bold" !important;
        padding-left: 5px;
        margin-bottom: 0;
      }
    }
  }
  .row {
    padding: 45px 28px;
    padding-bottom: 10px;
    .col-md-3,
    .col-sm-12 {
      margin-bottom: 41px;
    }
    .pagination {
      margin: 20px auto 17px;
      width: 100%;
    }
  }
  .overview__cam-identity {
    font-family: "Intersate-Bold", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    font-weight: 800;
    font-size: 12px;
    color: $primary;
    width: 92%;
    margin: 0 auto;
    border-bottom: 2px solid #bdbdbd;
    padding: 15px 6px;
  }
  &__cam-name {
    padding-top: 15px;
    font-size: 12px;
    padding-left: 16px;
    color: #0000009e;
  }
  &__company-name {
    font-size: 14px;
    padding-left: 16px;
    color: #0000009e;
    height: 20px;
  }
  .overview__cam-btn {
    border: none;
    background-color: transparent;
    margin-top: 0;
    position: absolute;
    right: 10px;
    top: 6px;
    &:focus {
      outline: none;
    }
  }
  .btn-div {
    .btn-primary {
      width: 60%;
      height: 42px;
      margin: 10px;
    }
    .btn-icon {
      margin-right: 5px;
    }
  }
  .overview-no-cameras {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 130px;
    font-size: 26px;
    align-items: center;
  }
  .cam-status {
    height: 100%;
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
      margin-left: 5px;
    }
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    .pagination-arrow {
      align-items: center;
      background: none;
      border: none;
      display: flex;
      font-size: 14px;
      margin: 15px;
      &:hover {
        color: #1181F2;
      }
      &:focus {
        outline: 0;
        border: none;
      }
    }
    .pagination__page {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .pagination__btn {
      align-items: center;
      display: flex;
      justify-content: center;
      background: $tertiary;
      box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
      border-radius: 80px;
      height: 35px;
      width: 35px;
      padding: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    &__camera-img {
      height: calc(130px + 13vw);
    }
  }
  @media screen and (max-width: 300px) {
    .overview {
      &__dropdown {
        .drop-down label {
          min-width: 0px !important;
          margin-right: 1em !important;
        }
        .drop-down {
          margin-left: 0px !important;
          left: 0px !important;
        }
      }
      .drop-down .drop-down-span {
        margin-right: 0px !important;
      }
    }
    .drop-down .drop-down-span {
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 550px) {
    padding-top: 12px;
    &__camera-img {
      height: calc(130px + 13vw);
    }
    &__newCamera-btn {
      .btn-primary {
        padding: 17px;
        width: 90%;
      }
    }
    &__header-btns {
      padding-left: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end
    }
    .row {
      padding-top: 20px;
      padding: 45px 6px 10px;
    }
    &__online-btn {
      display: block;
      position: absolute;
      top: -64px;
      right: 10px;
      width: 30% !important;
    }
    &__reset-btn {
      top: -51px;
    }
    &__dropdown {
      .dropdown-toggle {
        margin-bottom: 0px !important;
        z-index: 1 !important;
      }
      .drop-down {
        margin-left: 2rem;
        left: 7px;
        label {
          min-width: 4.5em !important;
          font-size: 12px !important;
        }
        .icon {
          right: 11px;
        }
      }
      .show>.btn-primary {
        font-size: 12px !important;
        margin-top: 0px !important;
      }
    }
    &__searchInput {
      width: 35vw;
      border-radius: 50px;
      height: 4.4em;
      color: black;
      border-color: #d4d4d4;
      border-image: none;
      box-shadow: none;
      border-style: none;
      border: 1px solid;
      padding: 0px 10px;
    }
  }
}