@import "../main.scss";
.livestream {
    height: 550px;
    width: 100%;
    position: relative;
    .livestream__close {
        border: none;
        background-color: transparent;
        position: absolute;
        right: 0;
        top: -30px;
        &:focus {
            outline: none;
        }
    }
    .livestream__image {
        border-radius: 20px;
        height: auto;
        width: 100%;
    }
    .livestream__live-cam {
        position: absolute;
        left: 0;
        margin-left: 5px;
        top: -35px;
        .livestream__live-name {
            color: black;
            font-family: $h1-font;
            font-size: 18px;
            margin-right: 14px;
        }
        .livestream__live-text {
            color: $secondary;
            font-family: $h1-font;
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 550px) {
    .livestream {
        margin-top: 30%;
        width: 100%;
    }
}