.button-primary {
  border-radius: 80px;
  min-width: 130px;
  height: 45px;
  font-family: 'Intersate-Light', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin: 5px;
  &:focus {
    outline: 0;
    border: none;
  }
  .loader-span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 550px) {
    width: 90%;
    height: 46px;
  }
}

.blue-button {
  box-shadow: 0 0 15px hsla(0, 0%, 89.8%, .7);
  border-radius: 80px;
  width: 200px;
  height: 45px;
  font-family: "Intersate-Light", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  background: #1181f2;
  color: #ffff;
}