@import "../../main.scss";
.historicalArchive__cover {
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  height: 100px;
  justify-content: center;
}

.navbar-hidden-box-hsa {
  border: none;
  padding: 2px;
  width: 100%;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.box-historical-archive {
  border: none;
  padding: 2px;
  width: 100%;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: calc(100vh - 350px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.box-historical-archive::-webkit-scrollbar,
.navbar-hidden-box-hsa::-webkit-scrollbar {
  width: 7px;
  height: 14px;
}

.box-historical-archive::-webkit-scrollbar-track,
.navbar-hidden-box-hsa::-webkit-scrollbar-track {
  border: none;
  border-radius: 10px;
}

.box-historical-archive::-webkit-scrollbar-thumb,
.navbar-hidden-box-hsa::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

.box-historical-archive::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

@media screen and (max-width: 550px) {
  .box-historical-archive {
    border: none;
    padding: 0px;
    width: 100%;
    height: 100vh !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .box-historical-archive::-webkit-scrollbar {
    display: none !important;
  }
}

@media screen and (min-width: 551px) {
  .delete-historical-archive-mobile {
    display: none;
  }
}

.historicalArchive__filter {
  align-items: center;
  box-shadow: 4px 23px 30px -33px #c4c4c4;
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: flex-end;
  padding: 0px 6%;
  .css-tlfecz-indicatorContainer {
    display: none;
  }
  label {
    margin-bottom: 0;
    margin-right: 5px;
  }
  .css-2b097c-container {
    width: 200px;
    .css-yk16xz-control {
      border-radius: 50px;
    }
    .css-1pahdxg-control {
      border-radius: 50px;
    }
    .css-1okebmr-indicatorSeparator,
    .css-6q0nyr-Svg {
      display: none;
    }
  }
  .historicalArchive__filter-dropdown {
    display: flex;
    flex-direction: row;
    margin-right: 12px;
    label {
      margin-bottom: 0.5rem !important;
    }
    .drop-down {
      align-items: center;
      justify-content: flex-end;
      display: flex;
      .drop-down-span {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        margin-top: 4px;
      }
      .icon {
        margin-top: 1px;
        right: 0;
      }
      .btn-primary {
        margin-right: 0;
        font-weight: bold;
      }
      .dropdown-toggle,
      .drop-down,
      .show>.btn-primary {
        border: none !important;
        font-size: 14px !important;
        margin-bottom: 0;
        margin-top: -4px !important;
        height: 26px !important;
        outline: none !important;
        padding-left: 0 !important;
        font-weight: bold;
        &:focus {
          outline: none !important;
          border: none !important;
          box-shadow: none;
        }
      }
      label {
        font-weight: 800;
        font-size: 14px;
        color: #0000009e;
        margin-right: 15px;
        margin-bottom: 0 !important;
        min-width: 5em !important;
      }
      img {
        right: 2px;
        top: 8px;
        width: 8px;
      }
    }
  }
  .historicalArchive__filter-search {
    width: 359px;
  }
  .css-2b097c-container {
    width: 200px;
    .css-yk16xz-control {
      border-radius: 50px;
    }
  }
  .historicalArchive__filter-reload-1 {
    display: none;
  }
  .historicalArchive__filter-reload-2 {
    margin-left: 12px;
  }
}

.historicalArchive__search-input {
  padding-left: 10px;
  width: 200px;
  height: 38px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 19px;
  display: flex;
  align-items: center;
  .historicalArchive__search-input-icon {
    width: 20px;
    height: 20px;
  }
  input {
    width: 76%;
    border: none !important;
    outline: none !important;
    padding-left: 10px;
    font-size: medium;
  }
}

.historicalArchive__table {
  font-size: 14px;
  padding: 24px calc(15px + 1.5vw);
  padding-bottom: 0 !important;
  width: 100%;
  .historicalArchive__table-head {
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    color: rgba(0, 0, 0, 0.62);
    display: flex;
    font-family: $h1-font;
    height: 48px;
    margin-bottom: 18px;
    width: 100%;
  }
  .historicalArchive__item {
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 40px;
    color: rgba(0, 0, 0, 0.62);
    display: flex;
    font-family: "Intersate-Light", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 6px 0;
    padding: 15px 0;
    width: 100%;
    justify-content: center;
    .historicalArchive__item-btn {
      background: $tertiary;
      box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
      border: none;
      border-radius: 80px;
      height: 42px;
      left: 1145px;
      padding: 0 24px;
      top: 470px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      &:focus {
        border: none;
        outline: 0;
      }
      &-download-icon {
        margin-right: 5px;
      }
    }
    .historicalArchive__item-edit {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      &-span {
        display: flex;
        justify-content: center;
        width: 100%;
        a {
          text-decoration: none;
        }
      }
    }
    .historicalArchive__item-picture {
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
      height: 45px;
      justify-content: center;
      width: 45px;
    }
    .historicalArchive__item-main-mobile {
      display: none;
    }
    .historicalArchive__item-name-box-show-project {
      display: none;
    }
    .historicalArchive__project {
      display: none;
    }
    .historicalArchive__item-main-desktop {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: 100%;
  }
}

.historicalArchive__sorting {
  cursor: pointer;
}

@media screen and (max-width: 1025px) {
  .historicalArchive {
    &__item-btn-download-icon {
      margin-right: 3px !important;
    }
    .historicalArchive__item-edit {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      &-span {
        justify-content: flex-start !important;
        width: 100%;
      }
    }
    .historicalArchive__filter {
      .drop-down .dropdown-menu.show {
        min-width: 95px;
      }
      .historicalArchive__filter-dropdown {
        .drop-down {
          label {
            margin-right: 0px;
          }
          .icon {
            margin-top: 1px;
            right: 0;
            position: absolute;
          }
        }
      }
      .historicalArchive__filter-reload-1 {
        display: block;
        margin-right: 10px;
      }
      .historicalArchive__filter-reload-2 {
        display: none;
      }
      .historicalArchive__search-input {
        padding-left: 10px;
        width: 141px;
        height: 3.8em;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 19px;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        .historicalArchive__search-input-icon {
          width: 17px;
          height: auto;
        }
        input {
          width: 50%;
          border: none !important;
          outline: none !important;
          padding-left: 0px;
          font-size: medium;
        }
      }
    }
    .historicalArchive__table {
      padding: 24px 16px;
      .historicalArchive__table-head {
        display: none;
      }
      .historicalArchive__item-btn {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: auto;
      }
      .historicalArchive__item {
        .historicalArchive__item-main-desktop {
          display: none;
        }
        .historicalArchive__item-main-mobile {
          display: flex;
          justify-content: space-between;
          width: 100%;
          text-align: center;
          align-items: center;
          padding: 0px 12px;
        }
        .historicalArchive__item-name-box {
          display: flex;
          flex-direction: row!important;
          align-items: center;
          flex-direction: column;
          margin: 10px;
          text-align: left;
        }
        .historicalArchive__item-name {
          font-family: $h1-font;
        }
        .historicalArchive__item-name-value {
          margin-bottom: 11px;
        }
        .historicalArchive__item-picture {
          margin: 10px;
        }
        .historicalArchive__info-avatar {
          margin-bottom: 4px;
          background-color: white;
          height: 41px;
          width: 43px;
          border-radius: 54px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .historicalArchive__item-name-box-show-project {
          display: flex;
          justify-content: space-between;
          width: 100%;
          background-color: white;
          height: 42px;
          margin-bottom: 21px;
          margin-top: 13px;
          margin-left: 16px;
          margin-right: 16px;
          border-radius: 20px;
          text-align: center;
          align-items: center;
          box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
          border-radius: 80px;
          .historicalArchive__arrow-up-avatar {
            width: 42px;
            height: 42px;
            background: $tertiary;
            box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
            border-radius: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 0px !important;
            padding: 0px;
          }
          .historicalArchive__item-name-show-project {
            padding-left: 10px;
            font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
            font-weight: 800;
            font-size: 12px;
            line-height: 140.62%;
            color: #2f91f4;
          }
        }
        .historicalArchive__project {
          display: flex;
          justify-content: space-between;
          padding: 0px 14px;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}