.googlemap {
  position: relative;
  .googlemap__pin {
    transition: all ease .2s;
  }
  .googlemap__label {
    align-items: flex-start;
    color: rgba(0, 0, 0, 0.62);
    background: #FFFFFF;
    border: 0.5px solid #C4C4C4;
    box-sizing: border-box;
    box-shadow: 0px 49px 39px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    padding: 15px;
    position: absolute;
    bottom: -10px;
    left: 14px;
    transition: all ease .2s;
    width: 220px;
    z-index: 1;
    .googlemap__location {
      display: flex;
      flex-direction: row;
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      margin-bottom: 5px;
    }
    .googlemap__project-company {
      font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
  }
}