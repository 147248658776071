.button-secondary {
  color: #1181f2;
  background-color: transparent;
  border: 2px solid #1181f2;
  border-radius: 50px;
  font-family: 'Intersate-Light', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
  padding: 1rem 1.3rem;
  min-width: 150px;
  &:hover {
    background-color: #b3c3d366;
  }
}