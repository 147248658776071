@import "../../main.scss";
.single-project {
  .single-project__cover {
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    display: flex;
    justify-content: space-between;
    padding: 32px;
    a {
      color: $primary;
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue",
        sans-serif;
      font-size: 14px;
      &:hover {
        text-decoration: none;
      }
    }
    h1 {
      font-family: $h1-font;
      font-size: 50px;
      padding: 24px 0px;
    }
    .single-project__location,
    .single-project__company {
      font-family: "Open Sans", "Roboto", "Droid Sans", "Helvetica Neue",
        sans-serif;
      font-size: 14px;
      padding: 5px 0px;
    }
    .single-project__cover-right {
      display: flex;
      flex-direction: column;
      button {
        background-color: $tertiary;
        box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
        border-radius: 80px;
        height: 50px;
        font-family: "Open Sans", "Roboto", "Droid Sans", "Helvetica Neue",
          sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        border: none;
        width: 185px;
        margin-bottom: 60px;
        &:focus {
          border: none;
          outline: 0;
        }
      }
      .single-project__cover-right-notification-desktop {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;
        align-content: center;
        label {
          font-family: "Intersate-Bold", "Roboto", "Droid Sans",
            "Helvetica Neue", sans-serif;
          margin-right: 25px;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.62);
        }
      }
    }
  }
  .single-project__cover-right-notification-mobile {
    display: none;
  }
  .single-project__edit {
    align-items: center;
    box-shadow: 4px 23px 30px -33px #c4c4c4;
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
    .single-project__edit-left {
      display: flex;
      .single-project__filter-btn {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        border: none;
        margin-right: 15px;
        outline: none !important;
        &:focus {
          border: none;
          outline: 0;
        }
      }
    }
    .single-project__edit-right {
      display: flex;
    }
  }
  .thumbnails-row {
    padding: 64px 32px;
    box-shadow: inset 0px 2px 30px rgba(0, 0, 0, 0.05);
    .thumbnails__col {
      margin-bottom: 28px;
      .single-project__thumbnails {
        .single-project__camerainfo {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: 24px 5px;
          .single-project__cameraname {
            font-family: $h1-font;
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.62);
          }
          .single-project__cameraname-link {
            cursor: pointer;
            &:hover {
              color: $primary;
            }
          }
          .single-project__cam-alias {
            font-family: $h1-font;
            font-size: 18px;
          }
          .single-project__date {
            align-items: center;
            display: flex;
            font-family: "Intersate-Bold";
            font-style: normal;
            font-weight: 900;
            font-size: 12px;
            line-height: 140.62%;
            color: $primary;
          }
        }
        .single-projects__camera-img-listview {
          border-radius: 28px;
          object-fit: cover;
          height: auto;
          width: 100%;
        }
        .single-projects__default-img {
          background: #e5e5e5;
          height: calc(100px + 12vw);
          width: 100%;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .single-projects__camera-image {
            border-radius: 28px;
            object-fit: cover;
          }
        }
        .single-project__btn-group {
          box-sizing: border-box;
          display: flex;
          margin-top: 18px;
          .single-project__live-btn {
            background-color: transparent;
            border: 2px solid #1181f2;
            border-radius: 50px;
            font-family: "Intersate-Light", "Roboto", "Droid Sans",
              "Helvetica Neue", sans-serif;
            font-size: 14px;
            margin-left: 2px;
            margin-right: 2px;
            padding: 1.5rem 1.3rem;
            min-width: 150px;
            &:hover {
              background-color: #b3c3d366;
            }
            &:focus {
              outline: 0;
            }
          }
          .single-project__archive-btn-list {
            background: $tertiary;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 80px;
            font-family: "Intersate-Bold", "Roboto", "Droid Sans",
              "Helvetica Neue", sans-serif;
            font-size: 14px;
            width: 280px;
            height: 58px;
            &:focus {
              outline: 0;
            }
          }
          .single-project__archive-btn {
            background-color: transparent;
            border: 2px solid #1181f2;
            border-radius: 50px;
            font-family: "Intersate-Light", "Roboto", "Droid Sans",
              "Helvetica Neue", sans-serif;
            font-size: 14px;
            margin-left: 2px;
            margin-right: 2px;
            padding: 1.5rem 1.3rem;
            flex: 1;
            &:hover {
              background-color: #b3c3d366;
            }
            &:focus {
              outline: 0;
            }
          }
        }
      }
    }
    .single-project__thumbnails-row-no-camera {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 130px;
      font-size: 26px;
      align-items: center;
      text-align: center;
    }
  }
  .single-project__cover-right-notification-switch {
    border: 1px solid #c4c4c4;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .switch-unchecked-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      justify-items: center;
      height: 100%;
      font-size: 10px;
      padding: 10px;
      font-style: normal;
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue",
        sans-serif;
      font-weight: normal;
    }
    .switch-checked-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      justify-items: center;
      height: 100%;
      font-size: 10px;
      padding: 10px;
      font-style: normal;
      font-family: "Intersate-Bold", "Roboto", "Droid Sans", "Helvetica Neue",
        sans-serif;
      font-weight: normal;
    }
  }
  &__return {
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}

@media screen and (min-width: 551px) {
  .single-project {
    .single-projects__camera-img {
      border-radius: 28px;
      object-fit: cover;
      height: calc(100px + 12vw);
      width: 100%;
    }
  }
}

@media screen and (max-width: 550px) {
  .single-project {
    .single-projects__camera-img {
      border-radius: 28px;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
    .single-project__cover {
      align-items: flex-start;
      box-sizing: border-box;
      flex-direction: column;
      h1 {
        font-size: 24px;
        padding: 16px 0;
      }
      .single-project__cover-right {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        margin-top: 10px;
        button {
          margin: 0px;
          width: 100%;
        }
        .single-project__cover-right-notification-desktop {
          display: none;
        }
      }
    }
    .single-project__cover-right-notification-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      width: 100%;
      label {
        font-family: "Open Sans", "Roboto", "Droid Sans", "Helvetica Neue",
          sans-serif;
        margin-right: 25px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.62);
      }
      .single-project__cover-right-notification-switch {
        border: 1px solid #c4c4c4;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .single-project__edit {
      padding: 17px;
      .single-project__edit-left {
        display: none;
      }
      .single-project__edit-right {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        width: 100%;
        button {
          box-sizing: border-box;
          flex: 1;
          font-size: 10px;
          margin: 0px 5px 0px 10px;
          width: 100%;
        }
      }
    }
    .thumbnails-row {
      .single-project__thumbnails-row-no-camera {
        font-size: 18px;
      }
      .thumbnails__col {
        padding: 0;
        .single-project__thumbnails {
          .single-project__btn-group {
            justify-content: center;
            .single-project__archive-btn {
              font-size: 12px;
            }
            .single-project__live-btn {
              font-size: 14px;
              width: 85px;
            }
          }
          .single-project__camerainfo {
            .single-project__cameraname {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.responsive-image-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  // justify-content: center;

  .responsive-image-flex__item {
    flex: 0 0 32.33%; // Default: 3 items per row
    max-width: 33.33%;
    // margin: 0 auto;

    @media (max-width: 1000px) {
      flex: 0 0 50%; // 2 items per row for medium screens
      max-width: 50%;
    }

    @media (max-width: 600px) {
      flex: 0 0 100%; // 1 item per row for small screens
      max-width: 100%;
    }

    .responsive-image-flex__img {
      height: 250px;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }

    .responsive-image-flex__button {
      background-color: transparent;
      border: 2px solid #1181f2;
      align-self: center;
      width: 100%;
      margin: 10px 0 5px;
      border-radius: 50px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      padding: 1.5rem 1.3rem;
      outline: none;
      transition: background-color 0.3s, border-color 0.3s;

      &:hover {
        background-color: #b3c3d366;
        border-color: #1181f2;
      }
    }
  }
}
