@import '../main.scss';
.header {
  background-color: $tertiary;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.header-banner {
  top: 30px !important;
}

.banner {
  text-align: center;
  background-color: rgba(255, 0, 0, 1) !important;
  color: #ffff;
  display: flex;
  height: fit-content;
  align-items: center;
  font-size: medium;
  text-overflow: ellipsis;
  font-weight: bold;
}

.mobile-banner {
  display: none;
}

@media only screen and (max-width: 767px) {
  .banner {
    font-size: 1rem;
    padding: 0 5px;
    text-align: left;
    background: none !important;
  }
  .special {
    display: none;
  }
  .header-banner {
    top: 0 !important;
  }
  .mobile-banner {
    display: block;
  }
}

.header__upper {
  align-items: center;
  background-color: $tertiary;
  box-shadow: 15px -21px 20px 10px #C4C4C4;
  display: flex;
  height: 62px;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  .header__upper-logo {
    margin-left: 36px;
    width: 120px;
  }
  .header__upper-menu {
    align-items: center;
    display: flex;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    margin-right: 36px;
  }
  .header__upper-signout {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .header__upper-language {
    align-items: center;
    display: flex;
    margin: 0px 32px;
    position: relative;
    div {
      margin: 0px 32px;
    }
    .header__languages {
      position: relative;
    }
    .header__language-choices {
      background-color: $tertiary;
      border: 1px solid rgba(168, 168, 168, 0.62);
      border-radius: 5px;
      flex-direction: column;
      position: absolute;
      overflow: hidden;
      margin: 0;
      left: -22px;
      top: 0px;
      button {
        background-color: $tertiary;
        border: none;
        padding: 3px 20px;
        width: 100%;
        &:hover {
          background-color: $tertiary;
          color: $primary;
        }
      }
    }
  }
}

.header__upper-profile {
  align-items: center;
  display: flex;
  position: relative;
  .header__upper-avatar {
    align-items: center;
    background-color: $primary;
    border-radius: 50%;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-left: 32px;
    width: 32px;
    cursor: pointer;
  }
  .header__upper-name {
    background-color: $tertiary;
    border: 1px solid rgba(168, 168, 168, 0.62);
    border-radius: 7px;
    padding: 5px 15px;
    position: absolute;
    right: 0;
    text-transform: capitalize;
    top: 45px;
  }
}

.navbar {
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
  display: flex;
  background-color: #FAFAFA;
  height: 55px;
  position: relative;
  transition: all 0.3s ease;
}

.navbar-desktop {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0px;
  width: 100%;
  a {
    color: #939393;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    text-decoration: none!important;
    &:hover {
      text-decoration: none;
      color: #939393;
    }
  }
}

.navbar-desktop--superuser {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0px;
  width: 100%;
  a {
    color: #939393;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    text-decoration: none;
    margin-right: 60px;
  }
}

.header__extend-btn {
  background: $tertiary;
  border: none;
  border-radius: 80px;
  bottom: -11px;
  box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
  height: 22px;
  bottom: 10px;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto auto;
  margin: auto;
  position: absolute;
  width: 55px;
  &:focus {
    border: none;
    outline: 0;
  }
  .header__traiangle {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.help {
  position: relative;
  .help__text {
    cursor: pointer;
  }
  .help__modal {
    align-items: center;
    background-color: $tertiary;
    border: 0.5px solid #C4C4C4;
    border-radius: 16px;
    color: rgba(0, 0, 0, 0.62);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 28pt;
    justify-content: center;
    height: 150px;
    position: absolute;
    right: -32px;
    top: 32px;
    width: 284px;
    z-index: 15;
    div {
      display: flex;
      justify-content: space-between;
      padding: 0px 24px;
      width: 100%;
    }
    a {
      color: rgba(0, 0, 0, 0.62);
    }
  }
  .help__text--highlight {
    color: #B8B8B8;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
  }
}

.header__settings-modal {
  .modal-dialog {
    max-width: 700px!important;
  }
  .modal-content {
    width: 100%;
    height: 650px;
  }
  .setting-modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width: 550px) {
  .header__upper {
    .header__upper-menu {
      font-size: 11px;
      justify-content: space-between;
      width: 100%;
    }
    .header__upper-language {
      div {
        margin: 0px 9px 0px 0px;
      }
    }
    a {
      display: none;
    }
    .header__upper-menu {
      margin-right: 18px;
    }
  }
  .header__upper-profile {
    .header__upper-avatar {
      margin-left: 10px;
    }
  }
  .navbar {
    .navbar-desktop,
    .navbar-desktop--superuser {
      display: none;
    }
    ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      margin: 0;
      padding: 0px;
      width: 100%;
      a {
        color: #939393;
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-size: 11px;
        text-decoration: none;
      }
    }
  }
  .help {
    .help__modal {
      font-size: 12px;
      left: 0;
      width: 275px;
      a {
        display: block;
      }
    }
  }
}