label {
  color: rgba(0, 0, 0, 0.62);
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 600;
  input {
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
  }
}

.drop-down {
  .btn-primary,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    background: none;
    border: 1px solid #cec7c7;
    position: relative;
    outline: none;
    box-shadow: none;
    width: 100%;
    height: 58px;
    border-radius: 80px;
    margin-top: 14px;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: black;
    text-align: left;
    padding: 0px 17px;
    padding-right: 10px;
    &::after {
      content: none;
    }
  }
  .drop-down-span {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .dropdown-toggle,
  .btn,
  .btn-primary {
    &:hover,
    &:focus {
      background: none;
      color: black;
    }
  }
  .dropdown {
    width: 100%;
  }
  .icon {
    margin-top: 14px;
    right: 35px;
  }
  @media screen and (max-width: 550px) {
    .dropdown-toggle {
      width: 100%;
      height: 46px !important;
      margin-bottom: 12px;
    }
    .drop-down {
      .btn-primary,
      .btn-primary:not(:disabled):not(.disabled):active,
      .show>.btn-primary.dropdown-toggle {
        height: 46px !important;
      }
    }
    .icon {
      margin-top: 3px;
    }
  }
}