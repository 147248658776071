@import "../../main.scss";
.Archive__Head {
  padding-bottom: 10px;
  &-allProjects {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140.62%;
    color: $primary;
    cursor: pointer;
    width: 100px;
    display: flex;
  }
  &-arrow {
    margin-right: 5px;
    cursor: pointer;
  }
  &-Heading {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 70px;
    line-height: 140.62%;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-Address {
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140.62%;
    color: rgba(0, 0, 0, 0.62);
    margin-bottom: 5px;
  }
  &-Filter {
    align-items: center;
    display: flex;
  }
  &-dropdown {
    padding: 0 !important;
    margin-top: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    .dropdown {
      margin-left: 10px;
    }
    &-btns {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      &-download {
        display: flex;
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 140.62%;
        color: rgba(0, 0, 0, 0.62);
        padding: 20px;
        text-align: center;
        justify-content: center;
      }
    }
    .drop-down {
      width: 275px;
      align-items: center;
      display: flex;
      .btn-primary {
        margin-right: 0;
      }
      .dropdown-toggle,
      .drop-down,
      .show>.btn-primary {
        border: none !important;
        font-size: 14px !important;
        margin-top: -3px !important;
        height: 26px !important;
        outline: none !important;
        padding-left: 0 !important;
        margin-left: 5px;
        &:focus {
          outline: none !important;
          border: none !important;
          box-shadow: none;
        }
      }
      label {
        font-weight: 800;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.62);
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-style: bold;
        font-weight: 800;
        font-size: 14px;
        line-height: 140.62%;
        color: rgba(0, 0, 0, 0.62);
      }
      .btn-primary:not(:disabled):not(.disabled):active:focus,
      .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
      }
      .icon {
        right: -4px;
        top: 3px;
        cursor: pointer;
        position: inherit;
      }
    }
  }
  &-reload {
    margin-right: 8px;
  }
  &-primary-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  &-primary-Mr-10 {
    margin-right: 20px;
  }
  .download-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 760px) {
  .Archive__Head {
    padding: 20px 10px;
    &-primary-btns {
      flex-direction: row;
      padding-right: 0;
      padding-left: 0;
      button {
        margin: 0px 3px;
      }
    }
    &-dropdown-btns {
      display: flex;
      flex-direction: column;
      padding: 0px;
    }
    &-dropdown {
      flex-direction: column;
    }
    &-Filter {
      justify-content: center;
    }
    .drop-down {
      display: none;
    }
    &-Heading {
      font-size: 48px;
    }
  }
}