@import "../../main.scss";
.add-project {
  .icon {
    margin-top: -4px !important;
  }
  h2 {
    font-size: 40px;
    text-align: center;
  }
  .modal-dialog {
    max-width: 1000px;
    position: relative;
    .modal-content {
      border: none;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      outline: 0;
      padding: 40px 32px;
      position: relative;
    }
    .add-project__close-modal {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  form {
    margin-top: 25px;
    padding: 0 2rem;
    margin-bottom: 51px;
    label {
      margin-top: 20px;
    }
    .add-project__cameras {
      align-items: center;
      display: flex;
      margin-top: 36px;
    }
    .add-project__input-groupname {
      color: rgba(0, 0, 0, 0.62);
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin-top: 36px;
    }
    .add-project__camera-groupname {
      color: rgba(0, 0, 0, 0.62);
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin-right: 12px;
    }
    .add-project__camera-plus {
      cursor: pointer;
      .css-2613qy-menu {
        padding: 0;
        li {
          padding: 0;
        }
      }
    }
    .add-camera-column {
      margin-top: 24px;
      position: relative;
      input {
        opacity: 1 !important;
      }
      .add-camera__edit {
        background-color: $tertiary;
        cursor: pointer;
        position: absolute;
        z-index: 2;
        right: 24px;
        top: 16px;
        svg {
          margin-right: 10px;
        }
      }
      .add-camera__add-name {
        border: none;
        color: rgba(0, 0, 0, 0.62);
        font-family: 'Open Sans', 'Roboto', sans-serif;
        font-size: 12px;
        position: absolute;
        left: 120px;
        top: 17px;
        min-width: 50px;
        z-index: 1;
        &:focus {
          outline: none;
        }
      }
      .css-g1d714-ValueContainer {
        font-size: 12px;
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }
      .css-2b097c-container {
        font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }
      .css-yk16xz-control {
        border-radius: 40px;
        height: 54px;
      }
      .css-f0arjc-control {
        background-color: $tertiary;
        border-radius: 40px;
        height: 54px;
        .css-1hb7zxy-IndicatorsContainer {
          display: none;
        }
        .css-145fhl4-singleValue {
          color: rgb(0, 0, 0);
        }
      }
      .css-1pahdxg-control {
        border-radius: 40px;
        box-shadow: none;
        height: 54px;
        &:hover {
          border-color: $primary;
        }
      }
      .css-26l3qy-menu {
        border-radius: 16px;
        padding: 12px 24px;
      }
      .css-1okebmr-indicatorSeparator,
      .css-tlfecz-indicatorContainer,
      .css-6q0nyr-Svg {
        display: none;
      }
      .css-g1d714-ValueContainer {
        margin-left: 12px;
      }
      .css-1wa3eu0-placeholder {
        font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-size: 12px;
        margin-left: 10px;
      }
      .add-project__caret {
        position: absolute;
        z-index: 1;
        right: 26px;
        top: 18px;
      }
      .css-zk88xw-singleValue {
        margin-left: 10px;
      }
      .css-b8ldur-Input {
        caret-color: $tertiary;
      }
    }
    .drop-down {
      .dropdown-menu {
        max-height: 200px;
        overflow-y: auto;
      }
      .dropdown-menu::-webkit-scrollbar {
        width: 4px;
      }
      .dropdown-menu::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $tertiary;
        border-radius: 32px;
        background-color: $tertiary;
        margin: 15px 0px;
      }
      .dropdown-menu::-webkit-scrollbar-thumb {
        background: $primary;
        border-radius: 32px;
        height: 4px;
      }
      .dropdown-menu.show {
        width: 100%;
        margin-left: 45px !important;
      }
      .icon {
        position: absolute;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .add-project {
    form {
      .add-camera-column {
        .css-yk16xz-control,
        .css-1pahdxg-control {
          height: 46px;
        }
        .add-camera__edit {
          top: 13px;
        }
        .add-camera__add-name {
          top: 13px;
        }
      }
    }
    .modal-dialog {
      .modal-content {
        padding: 40px 5px;
        .drop-down .icon {
          right: 35px;
          top: 70px;
        }
      }
    }
  }
}