@import '../../main.scss';
.navbar-hidden-box {
  border: none;
  padding: 2px;
  width: 100%;
  height: calc(100vh - 255px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.historicalArchive__item-btn {
  background: $tertiary;
  box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
  border: none;
  border-radius: 80px;
  height: 42px;
  left: 1145px;
  padding: 0 10px;
  top: 470px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  &:focus {
    border: none;
    outline: 0;
  }
  &-download-icon {
    margin-right: 5px;
  }
}

.users__cover {
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
  display: flex;
  height: 80px;
  justify-content: center;
  padding: 0px 0px;
}

.loader {
  height: 100%;
  width: 100%;
}

.pagination {
  height: 65px;
  width: 100%;
  margin: 0;
}

.box-users {
  border: none;
  padding: 2px;
  width: 100%;
  height: calc(100vh - 320px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.box-users::-webkit-scrollbar,
.navbar-hidden-box::-webkit-scrollbar {
  width: 7px;
  height: 14px;
}

.box-users::-webkit-scrollbar-track,
.navbar-hidden-box::-webkit-scrollbar-track {
  border: none;
  border-radius: 10px;
}

.box-users::-webkit-scrollbar-thumb,
.navbar-hidden-box::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

.box-users::-webkit-scrollbar-thumb:hover,
.navbar-hidden-box::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

@media screen and (max-width: 550px) {
  .box-users,
  .navbar-hidden-box {
    border: none;
    padding: 0px;
    width: 100%;
    height: 100vh !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .box-users::-webkit-scrollbar,
  .navbar-hidden-box::-webkit-scrollbar {
    display: none !important;
  }
}

.users__filter {
  align-items: center;
  border-bottom: 1px solid rgba(229, 229, 229, 0.7);
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: flex-end;
  padding: 0px 6%;
  .css-tlfecz-indicatorContainer {
    display: none;
  }
  .users__filter-dropdown {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-right: 12px;
    .drop-down {
      justify-content: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;
      .drop-down-span {
        display: flex;
        align-items: initial;
      }
      .dropdown-menu {
        margin-left: -1em !important;
      }
      .btn-primary {
        margin-right: 0;
        font-weight: bold;
      }
      .dropdown-toggle,
      .drop-down,
      .show>.btn-primary {
        border: none !important;
        font-size: 14px !important;
        margin-bottom: 0;
        margin-top: 0px !important;
        height: 26px !important;
        outline: none !important;
        padding-left: 0 !important;
        font-weight: bold;
        ß &:focus {
          outline: none !important;
          border: none !important;
          box-shadow: none;
        }
      }
      label {
        font-weight: 800;
        font-size: 14px;
        color: #0000009e;
        margin-right: 15px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 0;
      }
      img {
        right: 2px;
        top: 8px;
        width: 8px;
      }
      .icon {
        left: 100px;
        margin-top: 5px;
      }
    }
  }
  .users__filter .user__filter-reload {
    margin-left: 0px !important;
  }
  .css-2b097c-container {
    width: 200px;
    .css-yk16xz-control {
      border-radius: 50px;
    }
    .css-1pahdxg-control {
      border-radius: 50px;
    }
    .css-1okebmr-indicatorSeparator,
    .css-6q0nyr-Svg {
      display: none;
    }
  }
  .user__filter-reload {
    margin-left: 10px;
  }
}

.users__projects {
  position: relative;
  .users__projects-list {
    background: #FFFFFF;
    border: 0.5px solid #C4C4C4;
    box-sizing: border-box;
    box-shadow: 0px 49px 39px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    display: none;
    height: auto;
    left: 15px;
    padding: 24px;
    position: absolute;
    min-width: 200px;
    z-index: 1;
    ul {
      padding-left: 12px;
      a {
        color: rgba(0, 0, 0, 0.62);
        text-decoration: none;
      }
      li {
        font-size: 14px;
        list-style: none;
        &:hover {
          color: #1181F2;
          font-weight: bold;
        }
      }
    }
  }
}

.users__item-btn {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
  border: none;
  border-radius: 80px;
  font-size: 12px;
  width: 50% !important;
  margin-bottom: 5px !important;
  margin-top: 50 !important;
  height: 42px !important;
  left: 1145px;
  padding: 0 0px;
  margin: 0 10px;
  top: 470px;
  &:focus {
    border: none;
    outline: 0;
  }
}

.users__item-btnx {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
  border: none;
  border-radius: 80px;
  float: left;
  font-size: 12px;
  width: 52px !important;
  margin-bottom: 5px !important;
  height: 42px !important;
  left: 1145px;
  padding: 0 0px;
  margin: 0 10px;
  top: 470px;
  &:focus {
    border: none;
    outline: 0;
  }
}

.users__table {
  font-size: 14px;
  padding: 0 calc(10px + 1.5vw);
  .users__table-head {
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
    color: rgba(0, 0, 0, 0.62);
    display: flex;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    height: 48px;
    margin-bottom: 5px;
  }
  .users__item {
    align-items: center;
    background-color: #F9F9F9;
    border-radius: 40px;
    color: rgba(0, 0, 0, 0.62);
    display: flex;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin: 6px 0;
    padding: 10px 0;
    .users__item-btn {
      background: #FFFFFF;
      box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
      border: none;
      border-radius: 80px;
      height: 42px;
      left: 1145px;
      padding: 0 24px;
      margin: 0 10px;
      top: 470px;
      &:focus {
        border: none;
        outline: 0;
      }
    }
    .user__item-avatar {
      display: flex;
      justify-content: flex-start;
    }
    .user__item-edit {
      display: flex;
      justify-content: flex-end;
    }
    .users__item-picture {
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
      height: 45px;
      justify-content: center;
      width: 45px;
    }
  }
}

@media screen and (min-width: 551px) {
  .users__item {
    .users__item-name-box {
      display: none;
    }
  }
}

@media screen and (max-width: 300px) {
  .users__filter {
    padding: 0.1em !important;
    .drop-down .drop-down-span {
      margin-right: 0;
    }
  }
  .users__filter .users__filter-dropdown .drop-down label {
    min-width: 0px !important;
  }
  .users__filter .users__filter-dropdown {
    margin-right: 0 !important;
  }
  .drop-down {
    margin-left: 0px !important;
    left: 0px !important;
    .btn-primary {
      padding-right: 0;
    }
  }
  .drop-down .drop-down-span {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 550px) {
  .users__filter-dropdown .drop-down label {
    font-size: 12px;
    min-width: 5em !important;
    margin-right: 0 !important;
  }
  .drop-down .drop-down-span {
    margin-right: 0px !important;
  }
  .users__filter .users__filter-dropdown {
    margin-right: 0 !important;
  }
  .users__table {
    padding: 24px 12px;
    .drop-down .drop-down-span {
      margin-right: 0;
    }
    .users__table-head {
      display: none;
    }
    .users__item-btn {
      width: 50%;
    }
    .users__item {
      .users__item-name-box {
        display: flex;
        flex-direction: column;
        margin: 10px;
      }
      .users__item-name {
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }
      .users__item-picture {
        margin: 10px;
      }
      .users__hide-column {
        display: none;
      }
    }
  }
}