@import "../../main.scss";
.overview-image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
}

.singleproject-image {
  width: 100%;
  height: calc(100px + 12vw);
  border-radius: 20px;
  object-fit: cover;
}

.overview__camera-projectname {
  font-family: "Intersate-Bold", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0 !important;
}

.singleproject-image__list {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.default_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-singleproject-img__list {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.default-singleproject-img {
  height: calc(100px + 12vw);
  width: 100%;
  object-fit: cover;
  img {
    border: 20px;
    width: 100%;
    border-radius: 20px;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 550px) {
  .singleproject-image,
  .default-singleproject-img {
    height: 100%;
  }
}