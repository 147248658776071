.select-dropdown {
  align-items: center;
  display: flex;
  flex-direction: row;
  .css-yk16xz-control {
    border: none;
    color: rgba(0, 0, 0, 0.62);
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 800;
    font-size: 12px;
    min-width: 100px;
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.62);
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 800;
    font-size: 12px;
    width: 100px;
    &:hover {
      border: none;
    }
  }
  .css-g1d714-ValueContainer {
    color: rgba(0, 0, 0, 0.62);
  }
  .css-1okebmr-indicatorSeparator,
  .css-tlfecz-indicatorContainer,
  .css-6q0nyr-Svg {
    display: none;
  }
}