$mobileL: 550px;
$black: #161616;
// ============= Global ============= //
html {
  box-sizing: border-box;
  font-size: 10px;
  @media (max-width: $mobileL) {
    font-size: 8px;
  }
}

body {
  font-size: 1.5rem;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: inherit;
}

.carousel-slide-list {
  list-style-type: none;
  padding: 0;
}

.carousel__social a {
  text-decoration: none;
  color: inherit;
}

// ============= Carousel ============= //
.carousel-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.carousel-container {
  position: relative;
  width: 90%;
}

.carousel-slide-list {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 48px;
}

.carousel-slide-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  transition: all 0.5s ease;
  height: 100%;
  &.s5 {
    transform: translateX(-48rem);
    opacity: 0;
  }
  &.s6 {
    transform: translateX(-36rem);
    opacity: 0;
  }
  &.s7 {
    transform: translateX(-24rem);
    opacity: 0;
  }
  &.s8 {
    transform: translateX(-12rem);
  }
  &.s1 {
    transform: translateX(0);
  }
  &.s2 {
    transform: translateX(12rem);
  }
  &.s3 {
    transform: translateX(24rem);
    opacity: 0;
  }
  &.s4 {
    transform: translateX(36rem);
    opacity: 0;
  }
}

.carousel-slide-item__img-link {
  align-items: center;
  height: 25rem;
  justify-content: center;
  width: 100%;
  display: flex;
  img {
    transition: all 0.5s ease;
  }
  img {
    object-fit: cover;
    width: 100%;
    transition: all 0.5s ease;
    filter: grayscale(1);
    &.active {
      filter: none;
    }
  }
}

.carousel-slide-item__body {
  height: 10%;
  position: absolute;
  bottom: -2.5rem;
  h4 {
    margin: 0.7rem 0 0 0;
    text-transform: uppercase;
  }
  p {
    margin: 0.7rem 0 0 0;
    font-size: 1.2rem;
    line-height: 1.3;
  }
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  margin: 0;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  &.prev-btn {
    left: -5rem;
  }
  &.next-btn {
    right: -5rem;
  }
  &:focus {
    outline: 0;
    border: none;
  }
}

.carousel-btn__arrow {
  border: solid $black;
  border-width: 0 0.4rem 0.4rem 0;
  display: inline-block;
  padding: 3px;
  height: 6rem;
  width: 6rem;
  &.left {
    transform: rotate(135deg);
  }
  &.right {
    transform: rotate(-45deg);
  }
}

.carousel__position {
  font-size: 16px;
  font-weight: normal;
}

@media (min-width: 551px) {
  .carousel-wrap {
    display: none;
  }
}

@media (max-width: $mobileL) {
  .carousel__name {
    font-size: 18px;
  }
  .carousel__position {
    font-size: 15px;
  }
  .carousel-slide-item {
    width: 13rem;
  }
  .carousel-slide-item__img-link img {
    height: 88%;
  }
  .carousel-container {
    width: 78%;
  }
  .carousel-dots {
    display: none;
  }
}