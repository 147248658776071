.createEditCamera {
  &__modal {
    .text-danger {
      margin-bottom: 10px;
      padding: 0 10px;
    }
    .modal-dialog {
      max-width: 480px;
      .modal-content {
        margin: 0 auto;
        border-radius: 20px;
        border: none;
      }
    }
    h2 {
      font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      text-align: center;
      margin-bottom: 40px;
    }
    .row {
      width: 84%;
      margin: 0 auto 12px;
      .inputField {
        height: 52px !important;
      }
    }
  }
  &__close-btn {
    margin-top: 22px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__cross-image {
    width: 21px;
    margin-right: 40px;
    cursor: pointer;
  }
  &__radioBtn {
    margin: 8px 0;
    cursor: pointer;
    input[type="radio"] {
      width: 10px;
      opacity: 0;
      position: relative;
      left: 22px;
      height: 20px;
      z-index: 2;
    }
    label {
      position: relative;
      left: 5px;
      top: 2px;
      cursor: pointer;
    }
    input[type="radio"]:checked+label::after {
      background: #737373;
    }
    &::focus {
      border-color: #737373;
    }
  }
  &__btn-div {
    display: flex;
    justify-content: center;
    margin: 26px 0 50px;
  }
  &__file {
    cursor: pointer;
    border: 1px dotted lightgrey;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    width: 100%;
    input {
      display: none;
    }
  }
  &__file-name {
    font-size: 14px;
  }
  .css-9l3uo3 {
    font-size: 14px;
  }
  @media screen and (max-width: 550px) {
    &__modal {
      .row {
        width: 100%;
      }
      h2 {
        font-size: 30px;
        width: 200px;
        margin: 0 auto;
        margin-bottom: 52px;
      }
    }
    &__btn-div {
      margin: 40px 0;
      .btn-primary {
        width: 90%;
      }
    }
  }
}

.create-edit-camera__timelapes {
  padding: 0 60px;
  label {
    margin-left: 10px;
  }
}

.testmode-section {
  margin-left: 25px;
  .css-ahj2mt-MuiTypography-root {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
  }
}