.out-of-service {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  &__inner {
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    img {
      width: 300px;
    }
    h2 {
      color: black!important;
      font-size: 28px;
      text-align: center;
      margin-top: 60px;
    }
  }
}
.MuiAlert-message	{
font-size: 1.5rem;
}