@import '../main.scss';
.log-event__cover {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0px 28px;
  h1 {
    font-size: 50px;
  }
}

.log-event__container {
  background-color: #FAFAFA;
  min-height: 100vh;
  padding: 48px 32px;
  font-size: 14px;
  &-inner {
    max-width: 1200px;
  }
  h2 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 45px;
  }
  p {
    font-size: 14px;
  }
  img {
    width: 450px;
  }
  .log-event__item {
    padding: 6px 0px;
    span {
      font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
    }
    .log-event__date {
      margin-right: 28px;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    height: 450px;
    align-items: center;
    width: 100%;
  }
}

.nav-tabs {
  border-bottom: 1px solid #f9f9f9!important;
}

.nav {
  .nav-link {
    color: #939393;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 20px;
  }
  .active {
    color: $primary!important;
  }
}

@media screen and (max-width: 550px) {
  .log-event__cover {
    height: 73px;
    h1 {
      font-size: 28px;
    }
  }
  .log-event__container {
    .log-event__item {
      padding: 4px 0px;
      span {
        font-size: 12px;
      }
      .log-event__date {
        font-weight: bolder;
        margin-right: 12px;
      }
    }
    img {
      width: 300px;
    }
    .loader {
      height: 390px;
    }
  }
}