@import '../../main.scss';
.pagination {
  height: 65px;
  width: 100%;
  margin: 0;
}

.loader {
  height: 100%;
  width: 100%;
}

.navbar-hidden-box {
  border: none;
  padding: 2px;
  width: 100%;
  height: calc(100vh - 525px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.box-users {
  border: none;
  padding: 2px;
  width: 100%;
  height: calc(100vh - 320px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.box-users::-webkit-scrollbar,
.navbar-hidden-box::-webkit-scrollbar {
  width: 7px;
  height: 14px;
}

.box-users::-webkit-scrollbar-track,
.navbar-hidden-box::-webkit-scrollbar-track {
  border: none;
  border-radius: 10px;
}

.box-users::-webkit-scrollbar-thumb,
.navbar-hidden-box::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

.box-users::-webkit-scrollbar-thumb:hover,
.navbar-hidden-box::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

@media screen and (max-width: 550px) {
  .box-users,
  .navbar-hidden-box {
    border: none;
    padding: 0px;
    width: 100%;
    height: 100vh !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .box-users::-webkit-scrollbar,
  .navbar-hidden-box::-webkit-scrollbar {
    display: none !important;
  }
}

.companies__cover {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  padding: 0px 16px;
}

.companies__filter {
  align-items: center;
  box-shadow: 0px 9px 15px -4px #c4c4c447;
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: flex-end;
  padding: 0px 6%;
  label {
    font-size: 14px;
    color: #0000009e;
    margin-right: 15px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .css-2b097c-container {
    width: 200px;
    .css-yk16xz-control {
      border-radius: 50px;
    }
    .css-1pahdxg-control {
      border-radius: 50px;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  .companies__filter-reload {
    margin-left: 12px;
  }
}

.companies__table {
  font-size: 14px;
  padding: 10px calc(56px + 1.5vw);
  .companies__table-head {
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
    color: rgba(0, 0, 0, 0.62);
    display: flex;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    height: 48px;
    margin-bottom: 18px;
  }
  .companies__item {
    align-items: center;
    background-color: #F9F9F9;
    border-radius: 40px;
    color: rgba(0, 0, 0, 0.62);
    display: flex;
    font-family: 'Open Sans', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin: 6px 0;
    padding: 10px 0 10px 10px;
    .companies__item-label {
      display: none;
    }
    .companies__item-btn {
      background: #FFFFFF;
      box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
      border: none;
      border-radius: 80px;
      font-size: 12px;
      height: 42px;
      left: 1145px;
      padding: 0 24px;
      margin: 0 10px;
      top: 470px;
      &:focus {
        border: none;
        outline: 0;
      }
    }
    .companies__item-btn--mobile {
      display: none;
    }
    .user__item-edit {
      display: flex;
      justify-content: flex-end;
    }
    .companies__project-list--mobile {
      background: #FFFFFF;
      border: 0.5px solid #C4C4C4;
      box-sizing: border-box;
      box-shadow: 0px 49px 39px rgba(0, 0, 0, 0.07);
      border-radius: 16px;
      display: none;
      height: auto;
      left: 15px;
      padding: 24px;
      position: absolute;
      top: 43px;
      width: 90%;
      z-index: 1;
      ul {
        padding-left: 12px;
        a {
          color: rgba(0, 0, 0, 0.62);
          text-decoration: none;
        }
        li {
          font-size: 14px;
          list-style: none;
          &:hover {
            color: $primary;
            font-weight: bold;
          }
        }
      }
    }
  }
  .projects-box {
    position: relative;
    .project-box__list {
      background: #FFFFFF;
      border: 0.5px solid #C4C4C4;
      box-sizing: border-box;
      box-shadow: 0px 49px 39px rgba(0, 0, 0, 0.07);
      border-radius: 16px;
      display: none;
      height: auto;
      left: 15px;
      padding: 24px;
      position: absolute;
      min-width: 250px;
      z-index: 1;
      ul {
        padding-left: 12px;
        a {
          color: rgba(0, 0, 0, 0.62);
          text-decoration: none;
        }
        li {
          font-size: 14px;
          list-style: none;
          &:hover {
            color: #1181F2;
            font-weight: bold;
          }
        }
      }
    }
  }
  .projects-box__item-btn {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
    border: none;
    border-radius: 80px;
    font-size: 12px;
    height: 42px;
    left: 1145px;
    padding: 0 24px;
    margin: 0 10px;
    top: 470px;
    &:focus {
      border: none;
      outline: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .projects-box--hide-column {
      display: none;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
  .pagination-arrow {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    font-size: 14px;
    margin: 0px;
    &:hover {
      color: #1181F2;
    }
    &:focus {
      outline: 0;
      border: none;
    }
  }
  .pagination__page {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .pagination__btn {
    align-items: center;
    display: flex;
    justify-content: center;
    background: $tertiary;
    box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
    border-radius: 80px;
    height: 35px;
    width: 35px;
    padding: 10px;
  }
}

@media screen and (min-width: 769px) {
  .companies__item {
    .companies__item-name-box {
      display: none;
    }
  }
}

@media screen and (max-width: 570px) {
  .companies__table {
    padding: 24px 12px;
    .companies__table-head {
      display: none;
    }
    .companies__item {
      margin: 12px 0px;
      padding-bottom: 18px;
      .companies__namebox {
        padding: 14px 24px;
      }
      .companies__item-label {
        display: block;
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }
      .companies__item-name-box {
        display: flex;
        flex-direction: column;
        margin: 10px;
      }
      .companies__item-name {
        font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }
      .companies__hide-column {
        display: none;
      }
      .companies__item-btn--mobile {
        display: block;
      }
      .companies__item-btn {
        margin: 0px 4px;
        width: 50%;
      }
    }
  }
}