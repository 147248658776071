$primary: #1181f2;
$secondary: #00B607;
$tertiary: #ffff;
$main-body-font: 'Open Sans',
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;
$h1-font: "Intersate-Bold",
"Roboto",
"Droid Sans",
"Helvetica Neue",
sans-serif;
$p-font: "Open Sans",
"Roboto",
"Droid Sans",
"Helvetica Neue",
sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@font-face {
  font-family: "Intersate-Light";
  src: url(../assets/fonts/Interstate-Light.otf);
}

@font-face {
  font-family: "Intersate-Bold";
  src: url(../assets/fonts/Interstate-Bold.otf);
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

h1 {
  font-family: $h1-font;
  font-size: 70px;
  font-weight: 900;
}

h2 {
  font-family: $h1-font;
  font-size: 50px;
  font-weight: 900;
}

h6 {
  font-family: $h1-font;
  font-size: 16px!important;
  font-weight: 900;
}

label {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

p {
  font-family: $p-font;
}

.wrapper {
  min-height: 100vh;
  overflow: hidden;
  transition: all 0.3s ease;
}

.text-danger {
  font-size: 14px;
}

.modal-backdrop.show {
  width: 100%;
}

.react-datepicker {
  width: 200px;
  font-size: 14px !important;
  font-family: "Intersate-Bold" !important;
  &__month-container {
    width: 100%;
  }
  &__current-month {
    font-size: 14px !important;
  }
  &__header {
    background: #fafafa !important;
  }
  &__day-names,
  &__week {
    display: flex;
    justify-content: space-around;
  }
  &__month {
    display: flex;
    flex-direction: column;
  }
  &__day-names,
  &__week {
    display: flex;
    justify-content: space-around;
  }
  &__navigation {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.rc-time-picker-panel {
  top: 463px !important;
  font-family: "Intersate-Bold" !important;
}

.ML-10 {
  margin-left: 10px !important;
}

.MR-10 {
  margin-right: 10px;
}

.dropdown-menu.show {
  top: 7px !important;
  left: -50px !important;
  width: 150px;
  border-radius: 16px;
  .dropdown-item {
    color: rgba(0, 0, 0, 0.62);
    font-family: "Open Sans";
    font-weight: bold;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0 20px !important;
    margin: 7px 0 !important;
    &:active {
      background: none;
    }
  }
}

.truncate {
  display: flex;
  align-items: center;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control,
button {
  font-family: "Intersate-Light", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  &:focus {
    border: none;
    outline: 0;
  }
}

input {
  &:focus {
    border: none;
    outline: 0;
  }
}

.btn-primary:focus {
  &:focus {
    border: none;
    outline: 0;
  }
}

.handle-empty-element {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 130px;
  font-size: 26px;
  align-items: center;
}

.css-10vnfs9-menu {
  width: 150px!important;
}

.css-1ngmqkl-singleValue {
  font-weight: 500;
  font-size: 14px;
}

.rmdp-header-values {
  display: flex;
  height: 35px;
}

.sticky-help-button {
  bottom: 10px;
  position: sticky;
  button {
    position: absolute;
    right: 20px;
    top: 80vh;
  }
}

@media screen and (max-width: 550px) {
  .rc-time-picker-panel {
    top: 557px !important;
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) and (min-width: 552px) {
  .rc-time-picker-panel {
    top: 570px !important;
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px) {
  .rc-time-picker-panel {
    top: 480px !important;
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1160px) {
  .rc-time-picker-panel {
    top: 395px !important;
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
  }
}