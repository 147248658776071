.ConfirmForgotPassword-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__baucamera-logo {
    width: 118px;
    height: 19px;
  }
  &__title {
    font-size: 42px;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 900;
    margin-top: 44px;
    margin-bottom: 34px;
    text-align: center;
  }
  &__title-success {
    font-size: 40px;
    font-family: 'Intersate-Bold', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 900;
    margin-top: 44px;
    margin-bottom: 34px;
    text-align: center;
  }
  &__login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  &__btn-submit {
    margin-top: 40px;
    box-shadow: 0px 0px 15px rgba(229, 229, 229, 0.7);
    border-radius: 80px;
    width: 200px;
    height: 45px;
    font-family: 'Intersate-Light', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    &:focus {
      outline: 0;
      border: none;
    }
    .loader-span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
      height: 46px;
    }
  }
  &__error {
    margin-top: 20px;
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 0px 10px;
  }
  &__back-to-login {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (max-width: 550px) {
    &__title {
      font-size: 36px;
    }
  }
}